import { LAYOUT } from '@admin/router/constant'

// 出库管理
const outboundRouter = {
  path: '/outbound-management',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/outbound-management/wave-strategy',
      name: 'waveStrategy',
      component: () => import('@admin/views/wms/outboundManagement/waveStrategy/index.vue'),
      meta: { title: '波次策略' },
    },
    {
      path: '/wave-strategy/edit',
      name: 'waveStrategyEdit',
      component: () => import('@admin/views/wms/outboundManagement/waveStrategy/edit.vue'),
      meta: { helpUrl: '', title: '波次策略新增/编辑' },
    },
    {
      path: '/outbound-management/sales-invoice',
      name: 'SalesInvoice',
      component: () => import('@admin/views/wms/outboundManagement/salesInvoice/index.vue'),
      meta: { title: '销售发货单' },
    },
    {
      path: '/distribution/invoice',
      name: 'outboundManagementInvoice',
      component: () =>
        import(
          '@admin/views/wms/outboundManagement/distributionManagement/invoice/index/index.vue'
        ),
      meta: { title: '配货单' },
    },
    {
      path: '/distribution/invoice-edit',
      name: 'outboundManagementInvoiceEdit',
      component: () =>
        import('@admin/views/wms/outboundManagement/distributionManagement/invoice/edit/index.vue'),
      meta: { title: '拣货单详情' },
    },
    {
      path: '/distribution/invoice-task-list',
      name: 'invoiceTaskList',
      component: () =>
        import(
          '@admin/views/wms/outboundManagement/distributionManagement/invoiceTaskList/index.vue'
        ),
      meta: { title: '配货任务列表' },
    },

    {
      path: '/inventory-management/warehouse-inventory',
      name: 'warehouseInventory',
      component: () =>
        import(
          '@admin/views/wms/outboundManagement/inventoryManagement/warehouseInventory/index.vue'
        ),
      meta: { title: '库存查询' },
    },
    {
      path: '/inventory-management/incoming-outgoing-logs',
      name: 'incomingOutgoingLogs',
      component: () =>
        import(
          '@admin/views/wms/outboundManagement/inventoryManagement/incomingOutgoingLogs/index.vue'
        ),
      meta: { title: '出入库日志' },
    },
    {
      path: '/inventory-management/stock-movement',
      name: 'stockMovement',
      component: () =>
        import('@admin/views/wms/outboundManagement/inventoryManagement/stockMovement/index.vue'),
      meta: { title: '库存移动' },
    },

    {
      path: '/work-management/delivery-task',
      name: 'deliveryTask',
      component: () =>
        import('@admin/views/wms/outboundManagement/workManagement/deliveryTask/index.vue'),
      meta: { title: '发货任务列表' },
    },
    {
      path: '/work-management/delivery-single',
      name: 'deliverySingle',
      component: () =>
        import('@admin/views/wms/outboundManagement/workManagement/deliverySingle/index.vue'),
      meta: { title: '单品发货' },
    },
    {
      path: '/work-management/delivery-multiple',
      name: 'deliveryMultiple',
      component: () =>
        import('@admin/views/wms/outboundManagement/workManagement/deliveryMultiple/index.vue'),
      meta: { title: '多品发货' },
    },

    {
      path: '/sorting/sorting-task',
      name: 'sortingTask',
      component: () =>
        import('@admin/views/wms/outboundManagement/sortingManagement/sortingTask/index.vue'),
      meta: { title: '分拣任务列表' },
    },
    {
      path: '/sorting/sorting-operation',
      name: 'sortingOperation',
      component: () =>
        import('@admin/views/wms/outboundManagement/sortingManagement/sortingOperation/index.vue'),
      meta: { title: '分拣作业' },
    },
    {
      path: '/exception-management/order-interception',
      name: 'orderInterception',
      component: () =>
        import(
          '@admin/views/wms/outboundManagement/exceptionManagement/orderInterception/index.vue'
        ),
      meta: { title: '订单拦截' },
    },
    {
      path: '/exception-management/distribution',
      name: 'distribution',
      component: () =>
        import('@admin/views/wms/outboundManagement/exceptionManagement/distribution/index.vue'),
      meta: { title: '配货异常' },
    },
    {
      path: '/exception-management/sorting',
      name: 'sorting',
      component: () =>
        import('@admin/views/wms/outboundManagement/exceptionManagement/sorting/index.vue'),
      meta: { title: '分拣异常' },
    },
    {
      path: '/exception-management/delivery',
      name: 'delivery',
      component: () =>
        import('@admin/views/wms/outboundManagement/exceptionManagement/delivery/index.vue'),
      meta: { title: '发货异常' },
    },
    {
      path: '/warehouse-work/stock-movement',
      name: 'stockMovement',
      component: () =>
        import('@admin/views/wms/outboundManagement/inventoryManagement/stockMovement/index.vue'),
      meta: { title: '库存移动' },
    },
    {
      path: '/outbound-management/delivery-workbench',
      name: 'WMSDeliveryWorkbench',
      component: () => import('@admin/views/wms/outboundManagement/deliveryWorkbench/index.vue'),
      meta: { title: '配货工作台' },
    },
    {
      path: '/outbound-management/picking-list',
      name: 'WMSPickingList',
      component: () => import('@admin/views/wms/outboundManagement/pickingList/index.vue'),
      meta: { title: '拣货单' },
    },
    {
      path: '/outbound-management/package-ship',
      name: 'WMSDeliveryPackageShip',
      component: () => import('@admin/views/wms/outboundManagement/packageShip/index.vue'),
      meta: { title: '打包作业' },
    },
  ],
}

export default [outboundRouter]
